
              @import "@/assets/css/variables.scss";
            












































.socialMedia {
  display: flex;
  img {
    width: 24px;
    margin-right: 5px;
  }
}
