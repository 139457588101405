#loginClient {
  .banner {
    width: 100vw;
    min-height: 130vh;
    position: relative;
    background: url($btmBackground) no-repeat center center;
    background-size: cover;

    .img {
      width: 100vw;
      height: 634px;
      background: url($background) 100% 50%;
    }

    .login-inner {
      margin: 0 auto;
      margin-top: -350px;

      /deep/ .form-wrapper {
        width: 100%;
        max-width: 412px;
        margin: 0 auto;
        border-radius: 8px;
        padding: 52px 50px;
        background-color: $white;
        position: relative;

        &::before {
          position: absolute;
          bottom: -68px;
          left: -4px;
          content: url(~@/assets/images/login/login_shadow.png);
        }
      }

      .footer {
        width: 1320px;
        margin: 0px 100px;
        font-size: 10px;
        color: $dark-gray;
        line-height: 1.8;
        text-align: justifyLeft;
        padding-top: 30px;
        margin-bottom: 100px;

        /deep/ a {
          color: rgb(238, 238, 238);
          text-decoration: underline;
        }

        .logo-img {
          background: url($btmLogo);
          @include rtl-sass-value(padding, 38px 157px 0 0, 38px 0 0 157px);
          background-size: 157px 38px;
          background-repeat: no-repeat;
          height: 38px;
        }

        .socialMediaTitle {
          font-size: 13px;
          color: $future-blue;
        }

        .socialMedia {
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  #loginClient .banner .login-inner .footer {
    max-width: 90%;
  }
}

@media (max-width: 1024px) {
  #loginClient .banner {
    .login-inner {
      .footer {
        max-width: 90%;
      }
    }
  }
}

@media (max-width: 768px) {
  #loginClient .banner .img {
    height: 550px;
  }
}

@media (max-width: 550px) {
  #loginClient .banner {
    .login-inner {
      padding: 0 20px;

      .form-wrapper {
        padding: 48px 24px;
        max-width: 90%;

        &::before {
          content: unset;
        }
      }

      .footer {
        margin: 80px auto;
      }
    }
  }
}
