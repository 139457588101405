h2 {
  font-size: 22px;
  margin-bottom: 25px;
  color: $black;
  font-weight: bold;
  text-align: center;
}
.resetPassword-box {
  margin-top: 40px;
  padding: 0 10px;

  .reset-success {
    text-align: center;

    img {
      width: 83px;
    }

    p {
      font-size: 15px;
      margin: 50px 0 70px 0;
      color: $blue;
    }
  }

  .form-box {
    width: 100%;
    margin: 0 auto;

    p {
      font-size: 14px;
      color: #8b8b8b;
      line-height: 1.2;
      margin-bottom: 20px;

      &.isError {
        border: 1px solid red;
      }
    }

    /deep/ .el-form {
      .el-form-item {
        .el-input__inner {
          border: none;
          background-color: $light-gray;

          &:hover {
            background-color: $light-gray;
            box-shadow: 2.007px 2.229px 7.56px 1.44px $gray-blue;
          }
        }
      }
    }
  }
}

.no-authroization {
  margin-top: 20px;
  font-size: 20px;
  color: rgb(0, 74, 189);
}

.el-form-item.is-error {
  margin-bottom: 50px;
}

.el-button {
  font-size: 18px;
  width: 100%;
  border-radius: 5px;
  background-color: rgb(0, 74, 189);
  color: $white;
  font-weight: bold;
  box-shadow: 0.347px 1.97px 3px 0px rgba(0, 0, 0, 0.31);
  border: 0;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    background-color: rgb(0, 74, 189) !important;
    color: $white !important;
  }
}

@media (max-width: 1024px) {
  .resetPassword-box {
    .form-box {
      p {
        line-height: 1.35;
      }
    }
  }
}

@media (max-width: 550px) {
  h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .resetPassword-box {
    margin-top: 0;
    padding: 0 30px;

    .reset-success {
      text-align: center;

      img {
        margin-top: 15px;
      }
      p {
        margin-bottom: 40px;
      }
    }
    .form-box {
      p {
        line-height: 1.35;
        padding: 0;
        margin-bottom: 10px;
      }
    }
  }
}
